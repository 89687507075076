import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppPage from './Page/AppPage';
import NotFoundPage from './Page/NotFountPage';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">      
      <Routes>
        <Route  path='/' element={<AppPage />}/>
        <Route  path='/home' element={<AppPage />}/>
        <Route  path='*' element={<NotFoundPage />}/>
      </Routes>
    </div>
  );
}

export default App;
